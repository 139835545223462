
// glyphicons icons
.icon-glyphicon {
  display: inline-block;
  width: 14px;
  height: 14px;
  line-height: 14px;
  vertical-align: text-top;
  background-image: url("/assets/sprite_icons/glyphicons-halflings.png");
}

.icon-list {
  background-position: -264px 0;
}

.icon-print {
  background-position: -96px -48px;
}

// Product Type Icons

.icon-product-type {
  position: absolute;
  top: 3px;
  left: 18px;
  width: 60px;
  height: 60px;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
}

.icon-dress {
  background-image: url("/assets/sprite_icons/dress.png");
}

.icon-straplessdress {
  background-image: url("/assets/sprite_icons/dress-strapless.png");
}

.icon-sleevedress {
  left: 15px;
  background-image: url("/assets/sprite_icons/dress-sleeve.png");
}

.icon-shirt {
  top: 5px;
  left: 3px;
  background-image: url("/assets/sprite_icons/shirt.png");
}

.icon-sweater {
  top: 5px;
  left: 1px;
  background-image: url("/assets/sprite_icons/sweater.png");
}

.icon-tshirt {
  top: 5px;
  left: 6px;
  background-image: url("/assets/sprite_icons/tshirt.png");
}

.icon-pants {
  top: 3px;
  left: 19px;
  background-image: url("/assets/sprite_icons/trousers.png");
}

.icon-panties {
  width: 58px;
  background-position: -162px 0;
  background-size: auto 70px;
}

.icon-skirt {
  top: 10px;
  left: 12px;
  background-image: url("/assets/sprite_icons/skirt.png");
}

.icon-jacketformal {
  top: 5px;
  left: 1px;
  background-image: url("/assets/sprite_icons/jacket-formal.png");
}

.icon-jacketprotective {
  top: 5px;
  left: 2px;
  background-image: url("/assets/sprite_icons/jacket-protective.png");
}

.icon-top {
  top: 5px;
  left: 14px;
  background-image: url("/assets/sprite_icons/top.png");
}

.icon-shorts {
  top: 11px;
  left: 15px;
  background-image: url("/assets/sprite_icons/shorts.png");
}

.icon-coat {
  top: 3px;
  left: 11px;
  background-image: url("/assets/sprite_icons/coat.png");
}

.icon-alinedress {
  top: 3px;
  left: 15px;
  background-image: url("/assets/sprite_icons/dress-a-line.png");
}

.icon-bag {
  top: 6px;
  left: 7px;
  background-image: url("/assets/sprite_icons/bag.png");
}

.icon-clutch {
  top: 16px;
  left: 9px;
  background-image: url("/assets/sprite_icons/clutch.png");
}

.icon-tunic {
  top: 2px;
  left: 14px;
  background-image: url("/assets/sprite_icons/tunic.png");
}

.icon-wallet {
  top: 13px;
  left: 9px;
  background-image: url("/assets/sprite_icons/wallet.png");
}

.icon-suitcase {
  top: 8px;
  left: 18px;
  background-image: url("/assets/sprite_icons/suitcase.png");
}

.icon-backpack {
  top: 7px;
  left: 15px;
  background-image: url("/assets/sprite_icons/backpack.png");
}

.icon-briefcase {
  top: 7px;
  left: 7px;
  background-image: url("/assets/sprite_icons/briefcase.png");
}
